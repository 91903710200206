import { config } from '../config';
import { authHeader } from '../helpers';
import axiosInstance from '../axiosInstance';

export const accountService = {
  getSummary,
  getTransactions,
  getTransactionsByAccount,
  getDaily,
  listDailyAccounts,
  getStatements,
  loadStatements,
  getStatementsCount,
  getStatementsFilter
};

function getSummary(clientId) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  let url = `${config.account_server}/summary`;
  if (clientId) {
    url = `${config.account_server}/summary/${clientId}`;
  }

  return axiosInstance.get(url, requestOptions).then((response) => {
    return response.data;
  });
}

function getDaily(accountId) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .get(`${config.account_server}/daily/${accountId}`, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function listDailyAccounts(clientId) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  let url = `${config.account_server}/daily/list`;
  if (clientId) {
    url = `${config.account_server}/daily/list/${clientId}`;
  }

  return axiosInstance.get(url, requestOptions).then((responce) => {
    return responce.data;
  });
}

function getTransactions() {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .get(`${config.account_server}/transactions`, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function getTransactionsByAccount(accountId) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .get(`${config.account_server}/transactions/${accountId}`, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function getStatements(accountId) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .get(`${config.account_server}/statements/${accountId}`, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function getStatementsFilter(filter) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .post(`${config.account_server}/statements/filter`, filter, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function getStatementsCount(accountId) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .get(`${config.account_server}/statements/count/${accountId}`, requestOptions)
    .then((responce) => {
      return responce.data;
    });
}

function loadStatements(data) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  const url = `${config.account_server}/statements/batch/${data.accountId}?offset=${data.offset}`;

  return axiosInstance
    .get(url, requestOptions)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
