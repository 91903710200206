import React from 'react';
import { Menu, MenuItem } from '@mui/material';
import { Avatar, IconButton, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import PersonIcon from '@mui/icons-material/Person';
import { useDispatch } from 'react-redux';
import { logout } from '../../../features/auth/auth.slice';
import { useNavigate } from 'react-router-dom';

const settings = [{ title: 'My Profile', link: '/user/profile' }, { title: 'Sign out' }];

const ProfileMenu = ({ anchorEl, handleClose }) => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleNavigate = (link) => {
    navigate(link);
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    setAnchorElUser(null);
  };

  const handleCloseMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <IconButton onClick={handleOpenUserMenu} sx={{ p: 1 }}>
        {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}
        <PersonIcon />
      </IconButton>
      <Menu
        sx={{ mt: '45px' }}
        id="profile-menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseMenu}>
        {settings.map((setting, index) =>
          setting.title !== 'Sign out' ? (
            <MenuItem key={index} onClick={() => handleNavigate(setting.link)}>
              <Typography textAlign="center">{setting.title}</Typography>
            </MenuItem>
          ) : (
            <MenuItem key={index} onClick={() => handleLogout()}>
              <Typography textAlign="center">{setting.title}</Typography>
            </MenuItem>
          )
        )}
      </Menu>
    </Box>
  );
};

export default ProfileMenu;
