import React, { Fragment } from 'react';
import MainMenuItems from '../ui/mainMenuItems';
import { useTitle } from '../../hooks/titleProvider';
import { Box } from '@mui/system';
import SystemNotifications from '../ui/systemNotifications';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';

const Home = () => {
  const { setTitle } = useTitle();
  setTitle('Home');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Fragment>
      <Box display="flex" flexDirection={isMobile ? "column-reverse"  : "row"} justifyContent={isMobile ? "" : "space-between"}> 
        <Box mt={isMobile ? 5 : 0}>
          <MainMenuItems />
        </Box>
        <SystemNotifications />
      </Box>
    </Fragment>
  );
};

export default Home;
