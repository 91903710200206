import axiosInstance from '../axiosInstance';
import { config } from '../config';
import { authHeader } from '../helpers';

const getAll = () => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance.get(`${config.notification_server}`, requestOptions).then((responce) => {
    return responce.data;
  });
};

const get = (id) => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .get(`${config.notification_server}/${id}`, requestOptions)
    .then((responce) => {
      return responce.data;
    });
};

const dismiss = (data) => {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .post(`${config.notification_server}/dismiss`, data, requestOptions)
    .then((responce) => {
      return responce.data;
    });
};

const update = (id, data) => {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .put(`${config.notification_server}/${id}`, data, requestOptions)
    .then((responce) => {
      return responce.data;
    });
};

const create = (data) => {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .post(`${config.notification_server}`, data, requestOptions)
    .then((responce) => {
      return responce.data;
    });
};

const remove = (id) => {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .delete(`${config.notification_server}/${id}`, requestOptions)
    .then((responce) => {
      return responce.data;
    });
};

const scheduleStatements = (data) => {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .post(`${config.notification_server}/schedule/statements`, data, requestOptions)
    .then((responce) => {
      return responce.data;
    });
};

export const notificationService = {
  getAll,
  get,
  update,
  create,
  remove,
  dismiss,
  scheduleStatements
};
