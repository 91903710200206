import React, { Fragment } from 'react';
import MainAppBar from '../components/ui/mainAppBar';
import { Box } from '@mui/material';
import LandingBanner from '../components/ui/banners/landingBanner';
import BannerProvider from '../hooks/bannerProvider';
import MainBottomNavigation from '../components/ui/mainBottomNavigation';
import { Stack } from '@mui/system';
import NoRubberBand from '../components/utils/noRubberBand';
import SystemNotifications from '../components/ui/systemNotifications';

const PrivateLayout = ({ children, ...props }) => {
  return (
    <BannerProvider>
      <Stack sx={{ paddingTop: '56px', paddingBottom: 'env(safe-area-inset-bottom)' }}>
        <MainAppBar />

        <LandingBanner />
        <Box component="main" sx={{ flexGrow: 1, p: 3, pb: 5 }}>
          {children}
        </Box>

        <MainBottomNavigation />
      </Stack>
    </BannerProvider>
  );
};

export default PrivateLayout;
