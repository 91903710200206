import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import NotificationIcon from '@mui/icons-material/Notifications';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  getNotifications,
  selectNotification,
  dismissNotification
} from '../../../features/notification/notification.slice';
import { IconButton } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  maxWidth: 500
}));

const SystemNotifications = (props) => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notification.notifications);
  const userId = useSelector((state) => state.auth.user._id);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);

  useEffect(() => {
    if (userId) {
      dispatch(getNotifications());
    }
  }, [dispatch, userId]);

  const handleStackClick = (notification) => {
    dispatch(selectNotification(notification));
    setSelectedNotification(notification);
    setIsDialogOpen(true);
  };

  const handleDismissNotification = (notification) => {
    let notificationToDismiss = {
      notificationId: notification._id,
      userId: userId,
      dismissed: true
    };
    dispatch(dismissNotification(notificationToDismiss))
      .unwrap()
      .then(() => dispatch(getNotifications()));
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <Box width={'500px'}>
      <Box sx={{ flexGrow: 1, overflow: 'hidden', px: 3, pl: 0 }}>
        {notifications &&
          notifications.map((item, index) => {
            return (
              <Item key={index} sx={{ my: 2, mx: 'auto', p: 2 }}>
                {item.dismissible && (
                  <IconButton
                    sx={{ float: 'right' }}
                    onClick={() => handleDismissNotification(item)}>
                    <CloseIcon />
                  </IconButton>
                )}
                <Stack
                  onClick={() => handleStackClick(item)}
                  spacing={2}
                  direction="row"
                  alignItems="center">
                  <Avatar>
                    {item.notificationType === 'system' ? <SettingsIcon /> : <NotificationIcon />}
                  </Avatar>
                  <Box display={'flex'} flexDirection={'column'} sx={{ overflow: 'hidden' }}>
                    <Typography variant="h5" alignSelf={'flex-start'}>
                      {item.name}
                    </Typography>
                    <Typography noWrap>{item.description}</Typography>
                  </Box>
                </Stack>
              </Item>
            );
          })}
      </Box>
      <Dialog open={isDialogOpen} onClose={handleClose} maxWidth="md">
        <DialogTitle>{selectedNotification ? selectedNotification.name : ''}</DialogTitle>
        <DialogContent>
          {selectedNotification ? selectedNotification.description : ''}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SystemNotifications;
