import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Box, CardActions, CardContent, Typography, useMediaQuery } from '@mui/material';
import PasswordResetDialog from '../dialogs/passwordResetDialog';
import ClientAccountCard from '../partials/clientAccountCard';
import PrimaryButton from '../../ui/buttons/primaryButton';
import { useTitle } from '../../../hooks/titleProvider';
import FCard from '../../ui/fairtreeComponents/card/fCard';
import FCardHeader from '../../ui/fairtreeComponents/card/fCardHeader';
import Input from '../../ui/forms/fields/input';
import { useNavigate } from 'react-router-dom';

import FamilyIcon from '@mui/icons-material/Diversity1';
import EntityIcon from '@mui/icons-material/AccountBalance';
import PersonIcon from '@mui/icons-material/Person';

function Profile() {
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const { setTitle } = useTitle();
  setTitle('My Profile');

  const handleAccountLinkFamily = () => {
    navigate('/onboarding/family');
  };

  const handleAccountLinkEntity = () => {
    navigate('/onboarding/entity');
  };

  return (
    <Box display={'flex'} justifyContent={'center'}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        mb={3}
        width={'100%'}
        maxWidth={'700px'}>
        <FCard>
          <FCardHeader title={'Personal Details'} />
          <CardContent>
            <Input label={'First Name'} value={user.firstName} disabled />
            <Input label={'Last Name'} value={user.lastName} disabled />
            <Input label={'Email Address'} type="email" value={user.email} disabled />
            <Input
              label={'Phone Number'}
              type="tel"
              value={user.phoneNumber ? user.phoneNumber : ''}
              disabled
            />
          </CardContent>
          <CardActions>
            <Box
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              justifyContent="flex-end"
              gap={2}
              width={'100%'}>
              <PasswordResetDialog />
              <PrimaryButton disabled variant="outlined" fullWidth={false} toolTip={'Coming Soon'}>
                Update Email Address
              </PrimaryButton>
              <PrimaryButton disabled variant="outlined" fullWidth={false} toolTip={'Coming Soon'}>
                Update Details
              </PrimaryButton>
            </Box>
          </CardActions>
        </FCard>

        <FCard>
          <FCardHeader title={'Linked Accounts'} subheader={'Link Multiple Accounts'} />
          <CardContent>
            {user.client && user.client.length > 0 ? (
              <ClientAccountCard />
            ) : (
              <Fragment>
                <Typography variant="body1">
                  Welcome to the Fairtree Investment Portal. You have not linked accounts yet, let's
                  get you started.
                </Typography>
              </Fragment>
            )}
          </CardContent>
          <CardActions>
            <Box
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              justifyContent="flex-end"
              gap={2}
              width={'100%'}
              pr={1}>
              <PrimaryButton onClick={() => handleAccountLinkFamily()}>
                <PersonIcon />
                <Typography sx={{ ml: 1, fontWeight: '500', fontSize: '10px' }}>
                  Link Personal Account
                </Typography>
              </PrimaryButton>
              <PrimaryButton onClick={() => handleAccountLinkFamily()}>
                <FamilyIcon />
                <Typography sx={{ ml: 1, fontWeight: '500', fontSize: '10px' }}>
                  Link Family Account
                </Typography>
              </PrimaryButton>
              <PrimaryButton onClick={() => handleAccountLinkEntity()}>
                <EntityIcon />
                <Typography sx={{ ml: 1, fontWeight: '500', fontSize: '10px' }}>
                  Link Organization/Trust Account
                </Typography>
              </PrimaryButton>
            </Box>
          </CardActions>
        </FCard>
      </Box>
    </Box>
  );
}

export default Profile;
